









import Vue from 'vue';

import { Room } from '@/interfaces/rooms/Room';

export default Vue.extend({
  name: 'RoomRow',
  props: {
    room: {
      type: Object as () => Room,
    },
  },
});
