




















import Vue from 'vue';
import first from 'lodash/first';

import BackButtonMobile from '@/components/buttons/BackButtonMobile.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import RoomRow from '@/components/rooms/RoomRow.vue';

import { BuildingWithSelectedAreas } from '@/graphqlRequests/queries/BuildingWithSelectedAreas';
import { Room } from '@/interfaces/rooms/Room';
import { generateKey } from '@/helpers/StringGenerator';

export default Vue.extend({
  name: 'RoomSelector',
  components: {
    BackButtonMobile,
    BackButton,
    RoomRow,
  },
  data(): {
    buildingTopology: BuildingWithSelectedAreas.ContentDefinition | undefined;
  } {
    return {
      buildingTopology: undefined,
    };
  },
  apollo: {
    buildingTopology: {
      skip(): boolean {
        return (
          this.$store.state.selectedBuildingId == undefined
          || this.$store.state.selectedAreaId == undefined
        );
      },
      query: BuildingWithSelectedAreas.query,
      variables(): BuildingWithSelectedAreas.VariablesDefinition {
        return {
          buildingId: this.$store.state.selectedBuildingId,
          areaIds: [this.$store.state.selectedAreaId],
        };
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.$store.commit('addLoadingProcess', this.buildingTopologyLoadingKey);
        } else {
          this.$store.commit('stopLoadingProcess', this.buildingTopologyLoadingKey);
        }
      },
    },
  },
  computed: {
    buildingTopologyLoadingKey(): string {
      return generateKey();
    },
    rooms(): Room[] {
      return first(this.buildingTopology?.areas || [])?.rooms || [];
    },
  },
  methods: {
    back() {
      window.setTimeout(
        () => {
          this.$store.commit('resetControlMode');
          this.$store.commit('resetSelectedArea');
          this.$router.push({ name: 'AreaSelector', });
        },
        100,
      );
    },
    selectRoom(room: Room) {
      window.setTimeout(
        () => {
          this.$store.commit('setSelectedRoomId', room.id);
          this.$router.push({ name: 'RoomControl', });
        },
        100,
      );
    },
  },
});
